var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer-container", class: { static: _vm.isStatic } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", [_vm._v("© 2020-2024 liebide.com")]),
      _c(
        "a",
        {
          staticClass: "item",
          attrs: { href: "/Account/#/userAgreement", target: "_blank" },
        },
        [_vm._v("用户协议")]
      ),
      _c(
        "a",
        {
          staticClass: "item",
          attrs: { href: "/Account/#/privacyAgreement", target: "_blank" },
        },
        [_vm._v("隐私条款")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
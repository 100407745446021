<template>
    <el-cascader
        class="city-select"
        :props="{expandTrigger:'hover'}"
        :options="cities"
        :show-all-levels="false"
        :clearable="true"
        v-model="select.selectedCity"
        @change="selectCity"
    ></el-cascader>
</template>

<script>
    import provinceData from '#/js/config/provinceThreeData.json';
    import { getProvinceByCity } from '#/js/util/provinceCity.js';
    export default {
        name: "city-select",
        data() {
            return {
                cities: provinceData.province,
                select: {
                    selectedCity: []
                },
            }
        },
        methods: {
            selectCity(val){
                this.$emit('citySelected', val[0], val[1]);
            },
            reset() {
                this.select.selectedCity = [];
            },
            setDefaultCity(cid) {
                let provinceId = getProvinceByCity(cid).id;
                this.select.selectedCity = [ +provinceId, cid]
            },
            clearCity() {
                this.select.selectedCity = [];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .city-select {
        width: 100%;
    }
</style>

<template>
    <div class="footer-container" :class="{'static': isStatic}">
        <!-- <p>© 2024 liebide.com</p> -->
        <p>
            <i>© 2020-2024 liebide.com</i>
            <a class="item" href="/Account/#/userAgreement" target="_blank">用户协议</a>
            <a class="item" href="/Account/#/privacyAgreement" target="_blank">隐私条款</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'footer-bar',
    props: {
        isStatic: false,
    },
	components: {
	},
	data() {
		return {
        };
    },
    created() {
    },
	mounted() {
	},
	methods: {}
}
</script>
<style lang="scss" >
.footer-container{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // bottom: -20px;
    // height: 56px;
    // line-height: 56px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #999;
    // background-color: #f5f5f5;
    background-color: #fff;
    z-index: 11;
    >p{
        margin: 0;
        i {
            margin-right: 30px;
        }
        .item {
            color: #999;
            margin-right: 10px;
            &:hover {
                color: $primary;
            }
        }
    }
}
.static {
    position: static;
}
</style>
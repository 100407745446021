var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-cascader", {
    staticClass: "city-select",
    attrs: {
      props: { expandTrigger: "hover" },
      options: _vm.cities,
      "show-all-levels": false,
      clearable: true,
    },
    on: { change: _vm.selectCity },
    model: {
      value: _vm.select.selectedCity,
      callback: function ($$v) {
        _vm.$set(_vm.select, "selectedCity", $$v)
      },
      expression: "select.selectedCity",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }